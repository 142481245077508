import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { AdvancedTooltip, SaveArticle } from '@forbes/fbs-components';
import { reactComponentsTracking } from '../../shared/react-tracking';

const getSaveArticle = ({
	uri,
	articleId,
	element,
	isPremiumNotMasthead = false,
	onToggle,
	tooltipText,
	isTooltipVisible,
	isMastheadExcluded,
	isSocialIconsNotExist,
	isBrandVoice,
	isDateWrapperExist,
}) => {
	const trackingProps = {
		saveLabel: `save-article-save-click-${uri}`,
		unSaveLabel: `save-article-unsave-click-${uri}`,
		category: 'Template Area Interaction',
		action: 'click',
		track: reactComponentsTracking,
	};

	const tooltipProps = {
		isVisible: isTooltipVisible,
		position: 'left',
		label: 'Click to save this article.',
		text: tooltipText,
		cta: 'Got it',
		className: cx('save-article-tooltip', { 'premium-tooltip': isMastheadExcluded && !isBrandVoice, 'tooltip-without-social-icons': isSocialIconsNotExist, 'tooltip-without-date': !isBrandVoice && !isDateWrapperExist }),
		classNameTooltipArrow: 'tooltip-arrow',
		showCloseIcon: true,
	};

	ReactDOM.render(
		<>
			<SaveArticle classNameSaved={isPremiumNotMasthead && 'premium-saved-article'} className={isPremiumNotMasthead && 'premium-save-article'} onToggle={onToggle} articleId={articleId} trackingProps={trackingProps} />
			<AdvancedTooltip {...tooltipProps} />
		</>,
		element);
};

const moreForYou = document.querySelectorAll('.seo-save-article');

moreForYou.forEach((forYouElement) => {
	const { uri, naturalid: articleId } = forYouElement.dataset;
	getSaveArticle({
		uri, articleId, element: forYouElement, isTooltipVisible: false,
	});
});

export default getSaveArticle;
