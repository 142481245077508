import { fireGAEvent } from './tracking';
import checkStatus from './checkStatus';
import showOpenWeb from './showOpenWeb';

const { isMobile } = require('./is-mobile');
const {
	// setAcknowledgement,
	// getAcknowledgement,
	acceptAcknowledgement,
	// updateSessionCount,
} = require('./tooltipAck');

function hideTooltip(toolTipText, anchorButton) {
	acceptAcknowledgement('cmtAck');
	toolTipText.classList.remove('tooltip-show');
	anchorButton?.classList.remove('hover-show');
}

/**
 * Update HTML for openWeb commenting counter
 * @param {Object} data contains number of comments data.
 * @param {Number} streamArticleIndex Current stream position of the next article in the stream.
 * @param {String} articleId current article id.
 */
function updateCommentingDivHTML(data, streamArticleIndex, articleId) {
	const openWebContainer = document.querySelector(`#article-stream-${streamArticleIndex}`);
	const commentsCountDiv = openWebContainer.querySelector(`.replies-count__container[onclick="location.href='#open-web-${streamArticleIndex}'"]`);
	const commentContainer = openWebContainer.querySelector('.openWebHeaderContainer');
	const countContainer = commentContainer?.querySelector(`.${articleId}`);
	const emoji = commentContainer?.querySelector('.emoji');

	const commentsCount = data?.messages_count[articleId] || 0;

	if (countContainer) {
		emoji.innerHTML = commentsCount >= 5 ? '&#128293; &nbsp;' : '';
		countContainer.innerText = commentsCount;
	}

	if (commentsCountDiv) {
		const messagesCountDiv = document.createElement('div');
		messagesCountDiv.className = 'messages-count';
		const link = document.createElement('a');
		link.innerText = commentsCount;
		messagesCountDiv.appendChild(link);
		commentsCountDiv.appendChild(messagesCountDiv);
	}
}

/**
 * Get number of comments for article from openWeb and update html.
 * @param {Number} streamArticleIndex Current stream position of the next article in the stream.
 * @param {String} articleId current article id.
 */
function updateNumberOfCommentsForArticle(streamArticleIndex, articleId) {
	const { isProd = false } = window.forbes['simple-site'];
	const spotId = isProd ? 'sp_qBnNq7ll' : 'sp_EgBmvxVv';

	if (streamArticleIndex === 0 || !articleId) {
		articleId = window.forbes['simple-site'].articleId;
	}

	if (articleId && articleId.startsWith('blogAndPostId')) {
		articleId = `content_${articleId.split('-')[1]}`;
	}

	fetch(`https://open-api.spot.im/v1/messages-count?spot_id=${spotId}&posts_ids=${articleId}`)
		.then((response) => {
			checkStatus(response);
			response.json()
				.then((json) => {
					// call a function to update the html
					updateCommentingDivHTML(json, streamArticleIndex, articleId);
				});
		})
		.catch((error) => console.error(error));
}

function getGADataAndTooltip(anchorButton) {
	// const anchorLink = document.querySelector('.open-web_anchor--link');
	// const toolTipText = document.querySelector('#article-stream-0 .tooltip-text:not(.following)');

	const GAData = {
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: 'Commenting Icon',
	};

	if (anchorButton) {
		// Click tracking for commenting icon
		anchorButton.addEventListener('click', () => {
			fireGAEvent(GAData);
		});
	}

	// animate toolTip after 5sec
	// if (toolTipText) {
	// 	const ackFromStorage = getAcknowledgement('cmtAck');
	// 	const ackData = ackFromStorage ? JSON.parse(ackFromStorage) : {};

	// 	if (!window.location.href.includes('utm_source=AudioToolTip') && (!ackFromStorage || ackData.session === 3)) {
	// 		setAcknowledgement('cmtAck');

	// 		setTimeout(() => {
	// 			toolTipText.classList.add('tooltip-show');
	// 			anchorButton?.classList.add('hover-show');

	// 			// hide comment tooltip after clicking on comment icon
	// 			anchorLink.addEventListener('click', () => {
	// 				hideTooltip(toolTipText, anchorButton);
	// 			});
	// 		}, 5000);
	// 	}

	// 	if (ackData.session < 3) {
	// 		updateSessionCount('cmtAck', ackData.session);
	// 	}

	// 	toolTipText.addEventListener('click', () => {
	// 		hideTooltip(toolTipText, anchorButton);
	// 	});
	// }
}

function openWebShowTermsOverlay() {
	const openWebOverlayContainer = document.querySelector('.openWebTermsOverlayContainer');
	const openWebTermsOverlay = document.querySelector('.openWebTermsOverlay');
	const openWebCloseButton = document.querySelector('.closeIcon');

	openWebOverlayContainer.style.display = 'flex';
	document.body.style.overflow = 'hidden';

	openWebOverlayContainer.addEventListener('click', (e) => {
		if (!openWebTermsOverlay.contains(e.target)) {
			document.body.style.overflow = 'scroll';
			openWebOverlayContainer.style.display = 'none';
		}
	});

	openWebCloseButton.addEventListener('click', () => {
		document.body.style.overflow = 'scroll';
		openWebOverlayContainer.style.display = 'none';
	});
}

function openWebFunctionality(streamArticleIndex, anchorButton, openWebPlaceHolder, shouldShowOpenWeb) {
	const openWebContainer = document.querySelector(`#article-stream-${streamArticleIndex}`);
	const openWebOverlay = openWebContainer.querySelector('.openWeb-overlay');
	const closeButton = openWebContainer.querySelector('.close--icon');
	const html = document.querySelector('html');

	const GAData = {
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: 'Commenting Close',
	};

	anchorButton.addEventListener('click', () => {
		// eslint-disable-next-line no-underscore-dangle
		window.__OPEN_WEB__.initOW();
		openWebOverlay?.classList.add('view');
		openWebPlaceHolder.classList.add('openWeb-commenting--animation');
		html.style.setProperty('overflow', 'hidden');
	});

	closeButton.addEventListener('click', () => {
		openWebOverlay?.classList.remove('view');
		html.style.removeProperty('overflow');
		fireGAEvent(GAData);
	});

	if (shouldShowOpenWeb) {
		document.addEventListener('open-web-init', () => {
			anchorButton.click();
		});
	}
}

function actionOpenWeb(streamArticleIndex, naturalId) {
	if (!showOpenWeb()) {
		return;
	}

	const openWebPlaceHolder = document.querySelector(`#article-stream-${streamArticleIndex} .openWeb-commenting`);
	const anchorButton = document.querySelector(`#article-stream-${streamArticleIndex} .open-web_anchor--link`);
	const openWebShowTermsButton = document.querySelector('.openWebHeaderContainer .termsButton');

	if (openWebShowTermsButton) {
		openWebShowTermsButton.addEventListener('click', openWebShowTermsOverlay);
	}

	const shouldShowOpenWeb = new URLSearchParams(window.location.search).get('show-open-web');

	// Adds data for counter
	updateNumberOfCommentsForArticle(streamArticleIndex, naturalId);

	if (shouldShowOpenWeb && !isMobile) {
		document.getElementById('open-web-0').scrollIntoView();
	}

	getGADataAndTooltip(anchorButton);
	if (openWebPlaceHolder && isMobile) {
		openWebFunctionality(streamArticleIndex, anchorButton, openWebPlaceHolder, shouldShowOpenWeb);
	}
}

document.addEventListener('open-web-init', () => {
	window.history.pushState({}, '', window.location.href.replace(/show-open-web=true&|#open-web-\d/gi, ''));
});

export {
	showOpenWeb,
	actionOpenWeb,
	hideTooltip,
};
