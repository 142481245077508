import { streamObservable } from './streamService';

/**
 * @param {Object} serverData of the article in the stream
 */
function setUpInBodyVideos(serverData) {
	const currentArticle = document.querySelector(`#article-stream-${serverData.streamIndex}`);

	if (!currentArticle) {
		return;
	}

	const fbsVideos = currentArticle.querySelector('.body-container').querySelectorAll('fbs-video');
	const prerollEnabledSlots = ['iabpre1', 'iabpre2', 'iabpre3'];
	const hasPreroll = prerollEnabledSlots.indexOf(serverData.specialSlot) >= 0 || prerollEnabledSlots.indexOf(serverData.swimLane) >= 0;
	Array.from(fbsVideos).forEach((video) => {
		if (!video.hasAttribute('autoplay')) {
			if (hasPreroll) {
				video.setAttribute('key-value-string', 'pos=vid-iab');
			}
		}
	});
}

streamObservable.subscribe(setUpInBodyVideos);
